<template>
  <div>
    <Header />
      <el-row type="flex" class="account—settings">
        <el-col :span="3" style="width:15.3%;"></el-col>
        <el-col :span="17">
          <div class="settings_box">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>消息中心</el-breadcrumb-item>
              <el-breadcrumb-item>详细详情</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="display:flex;">
              <div class="container">
                <div class="container-button">
                  <div class="container-button-txt" @click="onrReturn">返回</div>
                </div>
                <div class="content">
                  <div class="content-left">
                    <div class="content-left-title">{{ msgDetailObj.msgTitle }}</div>
                    <div class="content-left-txt">{{ msgDetailObj.msgContent }}</div>
                  </div>
                  <div class="content-right">
                    <div class="content-left-date">{{ msgDetailObj.createTime }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
    </el-row>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Layout/header.vue'
import Footer from '@/components/Layout/footer.vue'
import { msgDetail } from "@/api/api"
export default {
  components: { Header, Footer },
  data () {
      return{
        msgDetailObj: {}
      }
  },
  created(){
    msgDetail(this.$route.query.id).then(res => {
      if (res.code == "00000") {
        this.msgDetailObj = res.data
      } else {
        this.$message({message: '网络出现问题，请重试！', type: 'error'})
      }
    });
  },
  methods:{
    // 返回
    onrReturn() {
      this.$router.go(-1)
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-width: 800px;
  border: 1px solid #898686;
  padding: 20px;
  border-radius: 5px;
}

.container-button {
  display: block;
  display: flex;
  margin-bottom: 20px;
}
.container-button-txt {
  padding: 10px 30px;
  border: 1px solid #898686;
  border-radius: 5px;
  cursor: pointer;
}
.content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.content-left-title {
  font-size: 17px;
  color: #333131;
}
.content-left-txt {
  font-size: 14px;
  color: #585656;
  margin: 10px 0;
}
.content-left {
  flex: 1;
}
.content-right {
  width: 148px;
  margin-left: 30px;
}
.content-left-date {
  font-size: 15px;
  color: #525050;
}
.settings_box{
  width: 100%;
  margin: 30px 0;
}
.el-breadcrumb{
  font-size: 18px;
  padding-bottom: 20px;
}
</style>